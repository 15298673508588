export type FeatureFlag = {
	env: string | undefined;
	/**
	 * Sets the default will override the environment and api
	 */
	defaultValue?: boolean;
};

function flags<T extends { [key: string]: FeatureFlag }>(x: T) {
	return x;
}

export const featureFlags = flags({
	FORCE_ERROR: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_FORCE_ERROR,
	},
	ADD_BANK_ACCOUNT_NAME: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ADD_BANK_ACCOUNT_NAME,
	},
	MUTATION_PERMISSIONS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_MUTATION_PERMISSIONS,
	},
	ALL_DOCUMENTS_TABLE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALL_DOCUMENTS_TABLE,
	},
	ALL_TRANSACTIONS_TABLE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALL_TRANSACTIONS_TABLE,
	},
	DISABLE_WALLETS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISABLE_WALLETS,
	},
	INVESTING_ENTITY_MANY_ACCOUNTS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_INVESTING_ENTITY_MANY_ACCOUNTS,
	},
	TRANSACTIONS_TABLE_EXPORT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS_TABLE_EXPORT,
	},
	TRANSACTIONS_TABLE_DATE_FILTER_ENABLED: {
		env: process.env
			.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS_TABLE_DATE_FILTER_ENABLED,
	},
	DOCUMENTS_TABLE_EXPORT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DOCUMENTS_TABLE_EXPORT,
	},
	DOCUMENTS_TABLE_DATE_FILTER_ENABLED: {
		env: process.env
			.NEXT_PUBLIC_FEATURE_FLAG_DOCUMENTS_TABLE_DATE_FILTER_ENABLED,
	},
	RWT_RATE_2800: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_RWT_RATE_2800,
	},
	SIMPLIFIED_SECONDARY_MARKET: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_SIMPLIFIED_SECONDARY_MARKET,
	},
	FUND_DOCUMENTS_MAINTENANCE: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_FUND_DOCUMENTS_MAINTENANCE,
	},
	HIDE_INVEST_TAB: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_HIDE_INVEST_TAB,
	},
	ALLOCATION_INVESTOR_PREFERENCES: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOCATION_INVESTOR_PREFERENCES,
	},
	ADMIN_REQUESTED_ACCOUNT_IDV: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ADMIN_REQUESTED_ACCOUNT_IDV,
	},
	UNIT_REDEMPTIONS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_REDEMPTIONS,
	},
	ALLOW_UNAUTHENTICATED_VIEWS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOW_UNAUTHENTICATED_VIEWS,
	},
	UNIT_CLASSES_V1: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_CLASSES_V1,
	},
	UNIT_CLASSES_V2: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_UNIT_CLASSES_V2,
	},
	DISTRIBUTION_REINVESTMENT: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISTRIBUTION_REINVESTMENT,
	},
	CUSTOM_INVESTOR_PORTAL_V3: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_CUSTOM_INVESTOR_PORTAL_V3,
	},
	EDITABLE_INVESTING_ENTITY_DETAILS: {
		env: process.env.NEXT_PUBLIC_FEATURE_FLAG_EDITABLE_INVESTING_ENTITY_DETAILS,
	},
});

export type PossibleFeatureFlags = keyof typeof featureFlags;
